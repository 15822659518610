goog.declareModuleId('yext.ui.components.select.native.NativeSelectGroup');

import yext from 'goog:yext';

import {NativeSelectList} from '/ui/components/select/native/nativeselectlist';
import {NativeSelectOption} from '/ui/components/select/native/nativeselectoption';

export function NativeSelectGroup(props) {
  return (
    <optgroup label={props.group.label}>
      {props.group.value
        && <NativeSelectOption option={props.group.withLabel(yext.msg('All'))} />}
      <NativeSelectList>
        {props.group.options.map(option =>
          option.options
            ? <NativeSelectGroup group={option} key={option.value}/>
            : <NativeSelectOption option={option} key={option.value}/>,
        )}
      </NativeSelectList>
    </optgroup>);
}
