/**
 * @fileoverview Select option data model
 */
// @ts-nocheck
goog.declareModuleId('yext.ui.components.select.model.Option');

import {Option as OptionType} from '/ui/components/select/types/option';
import arrays from 'goog:goog.array';

/**
 * An immutable model of the select compoment's options
 * @implements {OptionType}
 */
export class Option {
  constructor({
    value,
    label,
    secondaryText,
    selected,
    disabled,
    options,
    tid,
    pendoId,
    className,
    icon,
  }) {
    this.value = value;
    this.label = label;
    this.secondaryText = secondaryText;
    this.selected = selected;
    this.disabled = disabled;
    this.options = options;
    this.tid = tid;
    this.pendoId = pendoId;
    this.className = className;
    this.icon = icon;
  }

  withOptions(options) {
    return new Option({
      value: this.value,
      label: this.label,
      secondaryText: this.secondaryText,
      selected: this.selected,
      disabled: this.disabled,
      tid: this.tid,
      pendoId: this.pendoId,
      className: this.className,
      icon: this.icon,
      options,
    });
  }

  withoutOptions() {
    return new Option({
      value: this.value,
      label: this.label,
      secondaryText: this.secondaryText,
      selected: this.selected,
      disabled: this.disabled,
      tid: this.tid,
      pendoId: this.pendoId,
      icon: this.icon,
    });
  }

  withLabel(label) {
    return new Option({
      value: this.value,
      options: this.options,
      secondaryText: this.secondaryText,
      selected: this.selected,
      disabled: this.disabled,
      tid: this.tid,
      pendoId: this.pendoId,
      icon: this.icon,
      label,
    });
  }

  /** @override */
  toString() {
    return JSON.stringify(this, null, '  ');
  }

  static flatten(optionList) {
    return arrays.flatten(
      optionList.map(o => flatten(o, '')),
    );
  }

  /**
   * @param {!{value: (boolean|string|undefined),
   *           label: (string|undefined),
   *           secondaryText: (string|undefined),
   *           selected: (boolean|undefined),
   *           disabled: ?,
   *           tid: (string|undefined),
   *           pendoId: (string|undefined),
   *           options: (!Array|undefined),
   *           className: (string|undefined),
   * }} json
   * @return {!Option}
   */
  static fromJson(json) {
    const value = json.value || json['value'];
    const label = json.label || json['label'];
    const secondaryText = json.secondaryText || json['secondaryText'];
    const selected = !!(json.selected || json['selected']);
    const disabled = !!(json.disabled || json['disabled']);
    const tid = json.tid || json['tid'];
    const pendoId = json.pendoId || json['pendoId'];
    const children = json.options || json['options'];
    const className = json.className || json['className'];

    let options;
    if (Array.isArray(children) && children.length) {
      options = Option.fromJsonArray(children);
    }

    return new Option({
      value,
      label,
      secondaryText,
      selected,
      disabled,
      options,
      tid,
      pendoId,
      className,
    });
  }

  /**
   * @param {!Array<{value: (string|undefined),
   *           label: (string|undefined),
   *           secondaryText: (string|undefined),
   *           selected: (boolean|undefined),
   *           disabled: (boolean|undefined),
   *           tid: (string|undefined),
   *           pendoId: (string|undefined),
   *           options: (!Array|undefined),
   * }>} array
   * @return {!Array<Option>}
   */
  static fromJsonArray(array) {
    return array.map(Option.fromJson);
  }

  /**
   * @param {!HTMLOptionElement} element
   * @return {!Option}
   */
  static fromHtmlOption(element) {
    return new Option({
      value: element.value,
      label: element.label,
      selected: element.selected,
      disabled: element.disabled,
    });
  }

  /**
   * @param {?} elements
   * @return {!Array<Option>}
   */
  static fromHtmlCollection(elements) {
    return Array.from(elements)
      .map(Option.fromHtmlOption);
  }
}

/**
 * @param {?} option
 * @param {string} prefix
 * @return {!Array<!Option>}
 */
function flatten(option, prefix) {
  if (!option.options) {
    return [option];
  }

  const ret = [];

  const childrenWithoutChildren = option.options.filter(o => !o.options);
  if (childrenWithoutChildren.length) {
    const self = option.withLabel(prefix + option.label)
      .withOptions(childrenWithoutChildren);
    ret.push(self);
  }

  const childrenWithChildren = option.options.filter(o => o.options);
  for (const child of childrenWithChildren) {
    const flattened = flatten(child, `${prefix}${option.label} > `);
    [].push.apply(ret, flattened);
  }

  return ret;
}
