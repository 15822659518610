/**
 * This should be React.KeyboardEvent, but our externs are missing the type
 * @typedef {{
*   key: string,
*   preventDefault: (function(): void),
*   stopPropagation: (function(): void),
* }}
*/
let ReactKeyboardEvent;

/**
 * @param {string} id
 * @param {Function} setCursorPosition
 */
export function handleCursorMove(id, setCursorPosition) {
  const textArea = /** @type {HTMLTextAreaElement} */(document.querySelector('#'+id));
  setCursorPosition(textArea?.selectionStart);
}

/**
 * @param {string} prevValue
 * @param {string} value
 * @param {number|undefined} maxLength
 * @param {number} cursorPosition
 * @param {Function} onChange
 */
export function handleEmbeddedFieldClick(prevValue, value, maxLength, cursorPosition, onChange) {
  const isWithinMaxLen = (!maxLength) || (maxLength && (prevValue.length+value.length <= maxLength));
  if (isWithinMaxLen) {
    const leftHalf = prevValue.slice(0, cursorPosition);
    const rightHalf = prevValue.slice(cursorPosition);
    onChange(leftHalf + value + rightHalf);
  }
}

/**
 * @param {number} cursorPosition
 * @param {Function} setCursorPosition
 * @param {number} len
 * @return {Function}
 */
export function getContainerKeyboardHandler(cursorPosition, setCursorPosition, len) {
  /**
   * @param {ReactKeyboardEvent} event
   */
  function keydownHandler(event) {
    switch (event.key) {
      case 'ArrowLeft': {
        setCursorPosition(Math.max(0, cursorPosition-1));
        break;
      }
      case 'ArrowRight': {
        setCursorPosition(Math.min(cursorPosition+1, len));
        break;
      }
    }
  }
  return keydownHandler;
}
