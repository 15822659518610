/**
 * @fileoverview Select component filter bar
 */
// @ts-nocheck
goog.declareModuleId('yext.ui.components.select.FilterBar');

import yext from 'goog:yext';

function onKeyDown(event) {
  switch (event.key) {
    case 'ArrowDown':
    case 'ArrowUp':
    case 'Down': // IE11
    case 'Up': // IE11
      event.preventDefault();
      if (event.target.nextSibling) {
        event.target.nextSibling.focus();
      }
      break;
    case 'Enter':
      event.preventDefault();
      break;
  }
}

export function FilterBar(props) {
  return <input
    tid="select-search-input"
    data-pendo="select-search-input"
    type="text"
    className="ui-select-filter-bar"
    placeholder={props.placeholder ? props.placeholder : yext.msg('Search')}
    value={props.searchTerm}
    onChange={e => props.onSearch(e.target.value)}
    onKeyDown={onKeyDown} />;
}
