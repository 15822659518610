/**
 * @fileoverview Button component React interface.
 */
goog.declareModuleId('yext.ui.components.button');

import {concatScopes, usePendoId} from '/ui/components/pendo/pendo';
import {hasManaUITheme} from '/ui/design/hasManaUITheme';
import {classNames} from '/ui/lib/classnames';

import * as styles from '/ui/components/button/button.module.scss';

/**
 * @typedef {{
 *   children?: React.ReactNode,
 *   id?: string,
 *   onBlur?: ?,
 *   onClick?: ?,
 *   onFocus?: ?,
 *   tid?: (string|null),
 *   pendoId?: string,
 *   chromeless?: boolean,
 *   context?: string,
 *   disabled?: (boolean|null),
 *   fullWidth?: boolean,
 *   isMobile?: boolean,
 *   size?: string,
 *   type?: ?,
 *   margin?: string,
 *   padding?: string,
 *   ariaLabel?: string,
 * }|?}
 */
export let ButtonProps;

/** @type {function(ButtonProps): (React.ReactElement|null)} */
export const Button = React.forwardRef(({
  children,
  id,
  onBlur,
  onClick,
  onFocus,
  tid,
  pendoId,
  chromeless = false,
  context = 'default',
  disabled = false,
  fullWidth = false,
  isMobile = false,
  size = 'medium',
  type = 'button',
  margin,
  padding,
  ariaLabel,
  ...props
}, ref) => {
  const buttonClasses = classNames(
    styles.button,
    {
      [styles.small]: size === 'small',
      [styles.chromeless]: chromeless,
      [styles.defaultButton]: !chromeless && context === 'default', // outline-soft
      [styles.primary]: !chromeless && context === 'primary', // primary
      [styles.warning]: !chromeless && context === 'warning', // danger
      [styles.ghost]: !chromeless && context === 'ghost', // outline-soft
      [styles.fullWidth]: fullWidth,
      [styles.compactLayout]: isMobile,
      [styles.manaUiButton]: !chromeless && hasManaUITheme(),
      ['mana-times']: hasManaUITheme(),
    });

  // TODO: ask Covente if we want to use the pendoId exactly if it's passed in or
  // concatenate it with the context instead.  Concatenating would provide more
  // functionality, but would be a breaking change to existing tags.
  const pendoTag = usePendoId(concatScopes('button', context), pendoId);

  return (
    <button
      style={{margin, padding}}
      className={buttonClasses}
      disabled={disabled ?? undefined}
      id={id}
      data-pendo={pendoTag}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      tid={tid ?? undefined}
      type={type /* eslint-disable-line */}
      aria-label={ariaLabel}
      ref={ref}
      {...props}
    >
      {children ? <span>{children}</span> : null}
    </button>
  );
});

/**
 * @deprecated
 * @type {function(ButtonProps): (React.ReactElement|null)}
 */
export const DeprecatedOldButton = React.forwardRef(({
  children,
  id,
  onBlur,
  onClick,
  onFocus,
  tid,
  pendoId,
  chromeless = false,
  context = 'default',
  disabled = false,
  fullWidth = false,
  size = 'medium',
  type = 'button',
  margin,
  padding,
  ariaLabel,
  ...props
}, ref) => {
  const buttonClasses = classNames(
    'yext-button__button',
    {
      ['yext-button__button--small']: size === 'small',
      ['yext-button__button--chromeless']: chromeless,
      ['yext-button__button--default']: !chromeless && context === 'default',
      ['yext-button__button--primary']: !chromeless && context === 'primary',
      ['yext-button__button--warning']: !chromeless && context === 'warning',
      ['yext-button__button--full-width']: fullWidth,
      [styles.manaUiButton]: !chromeless && hasManaUITheme(),
    });

  const pendoTag = usePendoId(concatScopes('deprecated-button', context), pendoId);

  return (
    <button
      style={{margin, padding}}
      className={buttonClasses}
      disabled={disabled ?? undefined}
      id={id}
      data-pendo={pendoTag}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      tid={tid ?? undefined}
      type={type /* eslint-disable-line */}
      aria-label={ariaLabel}
      ref={ref}
      {...props}
    >
      {children ? <span>{children}</span> : null}
    </button>
  );
});
