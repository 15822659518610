require('/ui/components/dropdowntrigger/dropdowntrigger.module.scss.module.css');

export const dropdownMargin = "2";
export const smallViewSupport = "_small-view-support_sz9lt_1";
export const smallViewportSupport = "_small-viewport-support_sz9lt_1";
export const fontSizing = "_font-sizing_sz9lt_1";
export const containerCollapsed = "_container-collapsed_sz9lt_1";
export const portal = "_portal_sz9lt_1";
export const trigger = "_trigger_sz9lt_1";
export const manaUiTheme = "_mana-ui-theme_sz9lt_1";
export const triggerCombo = "_trigger-combo_sz9lt_1";
export const chromeless = "_chromeless_sz9lt_1";
export const primary = "_primary_sz9lt_1";
export const ghost = "_ghost_sz9lt_1";
export const collapsed = "_collapsed_sz9lt_1";
