/**
 * @fileoverview Type definition and related functions for options in select components
 * @author Chris Williams (cwilliams@yext.com)
 */
// @ts-nocheck
goog.declareModuleId('yext.ui.components.select.types.Option');

import array from 'goog:goog.array';
import functions from 'goog:goog.functions';
import {optionStates} from '/ui/components/select/model/optionstates';

/**
 * @typedef {{
 *   disabled?: boolean,
 *   label: React.ReactNode,
 *   secondaryText?: React.ReactNode,
 *   selected?: boolean,
 *   textContent?: string,
 *   options?: !Array<!Option>,
 *   value?: ?,
 *   tid?: string,
 *   pendoId?: string,
 *   className?: string,
 *   icon?: React.ReactNode,
 * }}
 */
export let Option = {};

/**
 * @param {Option|null|undefined} option
 * @return {symbol|null}
 */
Option.getStatus = option => {
  if (!option) {
    return null;
  }

  if (option.options && option.options.length) {
    const statuses = option.options.map(option => Option.getStatus(option));
    const deduped = [];
    array.removeDuplicates(statuses, deduped, functions.identity);

    if (deduped.length === 1) { // Status is the same for all
      return deduped[0];
    }

    return optionStates.INDETERMINATE;
  } else {
    return option.selected ? optionStates.SELECTED : optionStates.UNSELECTED;
  }
};
