import {usePendoId, concatScopes} from '/ui/components/pendo/pendo';
import {SelectController} from '/ui/components/select/selectcontroller';
import {Option} from '/ui/components/select/types/option';
import {IconTooltip} from '/ui/components/tooltip/tooltip';
import {classNames} from '/ui/lib/classnames';

import * as styles from '/ui/components/button/button.module.scss';

/**
 * @typedef {{
*   options: Array<Option>,
*   onSelect?: function(?):void,
 *  pendoId?: string,
* }}
*/
let EmbeddedFieldsButtonProps;

/** @type {function(EmbeddedFieldsButtonProps): (React.ReactElement|null)} */
export const EmbeddedFieldsButton = React.forwardRef(({
  options,
  onSelect,
  pendoId,
}, ref) => {
  const pendoTag = usePendoId('embedded-fields', pendoId);

  const Trigger = function({buttonRef, attributes}) {
    return (
      <button
        {...attributes}
        ref={buttonRef}
        data-pendo={pendoTag}
        className={classNames('js-embedded-fields-button', styles.button, styles.embeddedButton)}
        type="button">
        +
      </button>
    );
  };

  const element = (
    <span>
      <SelectController
        config={{
          name: 'embedded-fields-button',
          enableSearch: true,
          trigger: Trigger,
          onSelect: onSelect,
        }}
        options={options}
      />
    </span>
  );

  return (
    <IconTooltip
      element={element}
      tooltipText="Embed Field"
      direction="right"
    />
  );
});
