/**
 * @fileoverview Type definition and related functions for SelectController's config
 * @author Chris Williams (cwilliams@yext.com)
 */
goog.declareModuleId('yext.ui.components.select.types.Config');

import yext from 'goog:yext';

import {TriggerComponent} from '/ui/components/dropdowntrigger/dropdowntrigger';

/**
 * @param {!Array} options
 * @return {string}
 */
const DEFAULT_MULTISELECTLABELLER = options => yext.msgn('1 Selected', '{0} Selected', options.length);

/**
 * @typedef {{
 *   name?: string,
 *   placeholder?: string,
 *   multiselect?: boolean,
 *   multiselectLabeller?: (function(!Array): string),
 *   singleselectLabeller?: (function(!Array): string),
 *   onSelect?: (function(?):void),
 *   selectedOptions?: (!Array<string>|string),
 *   disabled?: boolean,
 *   actionLink?: {label: string, path?: string, pendoId?: string, onClick?: (function():void)},
 *   tid?: string,
 *   pendoId?: string,
 *   dropdownTriggerClass?: string,
 *   enableSearch?: boolean,
 *   searchPlaceholder?: string,
 *   trigger?: TriggerComponent,
 *   allowContainerOverflow?: boolean,
 *   alignMenu?: ?,
 *   alignCascade?: ?,
 *   chromeless?: boolean,
 *   selectAll?: boolean,
 *   primary?: boolean,
 *   ghost?: boolean,
 *   diacriticsNormalized?: boolean,
 * }}
 */
export let Config = {};

/**
 * @param {!Config} config
 * @return {!Config}
 */
Config.extendDefaults = function(config) {
  return Object.assign(
    {},
    {
      selectedOptions: [],
      onSelect: () => {},
      multiselectLabeller: DEFAULT_MULTISELECTLABELLER,
    },
    config);
};

/**
 * @param {!Object} json
 * @return {!Config}
 */
Config.fromJson = function(json) {
  return Config.extendDefaults({
    name: json['name'] || null,
    placeholder: json['placeholder'],
    multiselect: json['multiselect'] || false,
    multiselectLabeller: json['multiselectLabeller'] || DEFAULT_MULTISELECTLABELLER,
    selectedOptions: json['selectedOptions'] || [],
    alignMenu: json['alignMenu'],
    alignCascade: json['alignCascade'],
    chromeless: json['chromeless'],
    ghost: json['ghost'],
    actionLink: json['actionLink'],
    dropdownTriggerClass: json['dropdownTriggerClass'],
    selectAll: json['selectAll'] || false,
  });
};

/**
 * @param {?} element
 * @return {!Config}
 */
Config.fromHtmlSelect = function(element) {
  return Config.extendDefaults({
    name: element.name,
    selectedOptions: Array.from(element['selectedOptions']).map(o => o.value),
    multiselect: element.multiple,
  });
};
