// @ts-nocheck
goog.declareModuleId('yext.ui.components.select.root.SelectDropdownContent');

import {Checkbox} from '/ui/components/checkbox/checkbox';
import {FilterBar} from '/ui/components/select/filterbar/filterbar';
import {optionStates} from '/ui/components/select/model/optionstates';
import {FilteredSelectList} from '/ui/components/select/standard/filteredselectlist';
import {MultiSelectOption} from '/ui/components/select/standard/multiselectoption';
import {SelectActionLink} from '/ui/components/select/standard/selectactionlink';
import {SelectGroup} from '/ui/components/select/standard/selectgroup';
import {SelectList} from '/ui/components/select/standard/selectlist';
import {SelectOption} from '/ui/components/select/standard/selectoption';
import {Option} from '/ui/components/select/types/option';

import * as styles from '/ui/components/select/standard/standard.module.scss';

function containsNestedOptionsGreaterThanDepth(depth, options) {
  if (depth <= 0) {
    return options && options.length > 0;
  }
  return options && options.some(
    option => containsNestedOptionsGreaterThanDepth(depth - 1, option.options));
}

export function SelectDropdownContent(props) {
  const unfilteredHeight = React.useRef(undefined);

  // TODO(amullings): Check for non-casccading groups
  const isCascade = false;
  const role = props.hasCascade ? 'tree' : 'listbox';
  // use string keys to prevent advanced compilation
  const listAriaAttributes = {
    'role': role,
    'aria-multiselectable': props.multiselect,
  };
  const OptionComponent = props.multiselect ? MultiSelectOption : SelectOption;
  const labelClasses = ['ui-select-row js-select-target unittest-select-all'];
  if (props.additionalCssClass) {
    labelClasses.push(props.additionalCssClass);
  }

  const containsNestedOptionsGreaterThanDepth2 = React.useMemo(
    () => containsNestedOptionsGreaterThanDepth(2, props.options),
    [props.options]);

  let listView;

  // Currently, FilteredSelectList does not render options with depth > 2 properly, so avoid
  // using FilteredSelectList at all when options with depth > 2 are detected
  if (props.searchTerm !== '' && !containsNestedOptionsGreaterThanDepth2) {
    listView =
        <FilteredSelectList
          options={props.options}
          onOptionChange={props.onOptionChange}
          onSelectChange={props.setFocusOnSelectChange}
          multiselect={props.multiselect}
          onCloseDropdown={props.onCloseDropdown}
          isVisible={props.isVisible}
          height={unfilteredHeight.current}
        />;
  } else {
    listView = <SelectList
      tabIndex="-1"
      isCascade={isCascade}
      hasCascade={props.hasCascade}
      ariaAttributes={listAriaAttributes}
      isVisible={props.isVisible}
      listHeightCallback={h => {
        unfilteredHeight.current = h;
      }}
    >
      {props.selectAll && props.multiselect
          ?<li
            className={`${styles.selectAll}`}
            // use string keys to prevent advanced compilation
            {...{'aria-selected': props.selectAllState === optionStates.SELECTED}}
          >
            <div
              className={labelClasses.join(' ')}
            >
              <Checkbox
                disabled={false}
                onChange={() => {
                  if (props.selectAllState === optionStates.UNSELECTED) {
                    props.onSelectAll();
                  } else {
                    props.onSelectNone();
                  }
                }
                }
                checked={props.selectAllState === optionStates.SELECTED}
                indeterminate={props.selectAllState === optionStates.INDETERMINATE}
                name={yext.msg('Select All')}
                label={yext.msg('Select All')}
              >
              </Checkbox>
            </div>
          </li>
          : null
      }
      {props.options.map((option, index) => option.options && option.options.length > 0// True for cascading selects.
          ? <SelectGroup
            group={option}
            // For secondary-menu only selects, the value is undefined. In this case, just use index keys, since the selects are unlikely to be reordered anyways.
            // The labels are also likely to be unique, but we don't enforce that anywhere, so using the index is safer.
            key={option.value || `select-group-${index}`}
            onOptionChange={props.onOptionChange}
            onSelectChange={props.setFocusOnSelectChange}
            multiselect={props.multiselect}
            alignCascade={props.alignCascade} />
          : <OptionComponent
            option={option}
            key={option.value}
            onOptionChange={props.onOptionChange}
            onSelectChange={props.setFocusOnSelectChange}
            checked={Option.getStatus(option) === optionStates.SELECTED}
            indeterminate={Option.getStatus(option) === optionStates.INDETERMINATE}
            // use string keys to prevent advanced compilation
            ariaAttributes={{'role': props.hasCascade ? 'treeitem' : 'option'}} />)}
    </SelectList>;
  }

  return (
    <React.Fragment>
      {props.allowSearch
          && <FilterBar
            searchTerm={props.searchTerm}
            onSearch={props.onSearch}
            placeholder={props.searchPlaceholder}
          />
      }
      {listView}
      {
        props.actionLink
            && <SelectActionLink
              onCloseDropdown={props.onCloseDropdown}
              actionLink={props.actionLink}
            />
      }
    </React.Fragment>);
}
