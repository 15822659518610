/**
 * @fileoverview Input component React interface.
 */
// @ts-nocheck
goog.declareModuleId('yext.ui.components.input');

import yext from 'goog:yext';

import {EmbeddedFieldsButton} from '/ui/components/button/embeddedfieldbutton';
import {handleCursorMove, handleEmbeddedFieldClick, getContainerKeyboardHandler} from '/ui/components/button/embeddfieldbuttonhelper';
import {usePendoId, concatScopes} from '/ui/components/pendo/pendo';
import {Option} from '/ui/components/select/types/option';
import {classNames} from '/ui/lib/classnames';
import {useUid} from '/ui/lib/uid';

import * as styles from '/ui/components/input/input.module.scss';
import ArrowDown from '/ui/icons/inline/arrow-down.svg';
import ArrowUp from '/ui/icons/inline/arrow-up.svg';
import MediumX from '/ui/icons/inline/medium-x.svg';
import Search from '/ui/icons/inline/search.svg';


/**
 * @typedef {{
 *   errorMessage?: string,
 *   id?: string,
 *   label: React.ReactNode,
 *   max?: (string|number),
 *   maxLength?: number,
 *   min?: (string|number),
 *   onBlur?: Function,
 *   onChange?: Function,
 *   onFocus?: Function,
 *   onKeyPress?: Function,
 *   onKeyUp?: Function,
 *   pendoId?: string,
 *   placeholder?: string,
 *   readOnly?: boolean,
 *   searchOnRemove?: Function,
 *   step?: number,
 *   tid?: string,
 *   value?: (string|number),
 *   autocomplete?: string,
 *   disabled?: boolean,
 *   hideLabel?: boolean,
 *   name?: string,
 *   required?: boolean,
 *   options?: Array<Option>,
 *   showRemainingCharacters?: boolean,
 *   type?: string,
 *   valid?: boolean,
 *   ariaLabel?: string,
 *   ref?: ?,
 * }}
 */
let InputProps;

/** @type {function(!InputProps): React.ReactElement} */
export const Input = React.forwardRef(({
  ariaLabel,
  autocomplete = 'on',
  disabled = false,
  errorMessage,
  hideLabel = false,
  id = '',
  label,
  max,
  maxLength,
  min,
  name = id,
  onBlur,
  onChange,
  onFocus,
  onKeyPress,
  onKeyUp,
  options,
  pendoId,
  placeholder,
  readOnly = false,
  required = false,
  searchOnRemove,
  showRemainingCharacters = false,
  step = 1,
  tid,
  type = 'text',
  valid = true,
  value,
}, ref) => {
  const [cursorPosition, setCursorPosition] = React.useState(0);

  const inputClasses = classNames(
    'yext-input__input',
    styles.input,
    {
      ['yext-input__input--invalid']: !valid,
      ['yext-input__input--readonly']: readOnly,
    },
  );

  const labelClasses = classNames(
    'yext-input__label',
    styles.label,
    {
      ['yext-input__label--hidden']: hideLabel,
    },
  );

  const labelTextClasses = classNames(
    'yext-input__label-text',
    {
      ['yext-input__label-text--required']: required,
    },
  );

  /*
    Max/MinVal are used to set the maximum and minimum of the stepper range
    and aslo the default number to step to whenever there is no number in the input
    MaxVal defaults to max, then min, and then 0
    MinVal defaults to min, then max, then 0
  */
  const maxVal = max == undefined ? min == undefined ? 0 : min : max;
  const minVal = min == undefined ? max == undefined ? 0 : max : min;

  const fixFloat = num => {
    const decs = [0];
    if (step != undefined) {
      decs.push(Math.floor(step) == step ? 0 : step.toString().split('.')[1].length || 0);
    }
    if (max != undefined) {
      decs.push(Math.floor(max) == max ? 0 : max.toString().split('.')[1].length || 0);
    }
    if (min != undefined) {
      decs.push(Math.floor(min) == min ? 0 : min.toString().split('.')[1].length || 0);
    }
    const dec = Math.max(...decs);
    const result = Number(num.toFixed(dec));
    return (typeof value == 'string') ? result.toString() : result;
  };

  /*
    If adding step doesn't even bring the value to min, set the value to the minimum
    If adding step brings the value over max, set the value to maximum
    Else, add step to value
  */
  const onUpStep = e => {
    e.preventDefault();
    onChange(fixFloat((Number(value) + step < min) ? minVal
      : (Number(value) + step >= max) ? maxVal : (Number(value) + step)));
  };

  /*
    If subtracting step doesn't even bring the value below max, set the value to the maximum
    If subtracting step brings the value below min, set the value to minimum
    Else, subtract step from value
  */
  const onDownStep = e => {
    e.preventDefault();
    onChange(fixFloat((Number(value) - step > max) ? maxVal
      : (Number(value) - step <= min) ? minVal : (Number(value) - step)));
  };

  const uid = useUid();

  const keydownHandler = getContainerKeyboardHandler(cursorPosition, setCursorPosition, value ? value.length : 0);

  function handleInputChange(event) {
    onChange(event.target.value);
    handleCursorMove(id || uid, setCursorPosition);
  }

  const pendoTag = usePendoId(name || id || 'input', pendoId);

  return (
    <div className="yext-input__container" tid={tid}>
      {label && <label
        htmlFor={id || uid}
        className={labelClasses}
      >
        <span className={labelTextClasses}>{label}</span>
      </label>}
      {errorMessage
        && <span className="yext-input__error-message">{errorMessage}</span>}
      <div className={
        type === 'number'
          ? 'yext-input__input__container yext-input__input__container--number'
          : 'yext-input__input__container'
      }>
        <div className={styles.inputContainer}>
          <input
            className={inputClasses}
            disabled={disabled}
            id={id || uid}
            max={max && max}
            maxLength={maxLength && maxLength}
            min={min && min}
            name={name}
            onBlur={onBlur}
            onChange={handleInputChange}
            onFocus={onFocus}
            onMouseUp={() => handleCursorMove(id || uid, setCursorPosition)}
            onKeyDown={keydownHandler}
            onKeyPress={onKeyPress}
            onKeyUp={onKeyUp}
            placeholder={placeholder || ''}
            required={required}
            step={step && step}
            type={type === 'embeddedFields' ? 'search' : type}
            value={value}
            autoComplete={autocomplete}
            ref={ref}
            readOnly={readOnly}
            aria-label={ariaLabel}
            data-pendo={pendoTag}
          />
          {
            type === 'number'
              && <React.Fragment>
                <button
                  type="button"
                  className={styles.numberStepUp}
                  onClick={e => onUpStep(e)}
                  disabled={disabled || value >= max}
                  data-pendo={concatScopes(pendoTag, 'step-up')}
                  aria-label={yext.msg('Step Up')}
                >
                  <ArrowUp/>
                </button>
                <button
                  type="button"
                  className={styles.numberStepDown}
                  onClick={e => onDownStep(e)}
                  disabled={disabled || value <= min}
                  data-pendo={concatScopes(pendoTag, 'step-down')}
                  aria-label={yext.msg('Step Down')}
                >
                  <ArrowDown/>
                </button>
              </React.Fragment>
          }
          {
            type === 'search'
              && <button
                type="button"
                className={styles.searchButton}
                onClick={searchOnRemove}
                disabled={disabled || !value}
                data-pendo={concatScopes(pendoTag, 'clear-search')}
                aria-label={yext.msg('Clear search query')}
              >
                {value ? <MediumX/> : <Search/>}
              </button>
          }
          {
            type === 'embeddedFields'
              && (!disabled)
              && (<span className={styles.embeddedFields}>
                <EmbeddedFieldsButton
                  options={options ?? []}
                  onSelect={v => handleEmbeddedFieldClick(value, v, maxLength, cursorPosition, onChange)}
                />
              </span>)
          }
        </div>
        {maxLength
              && showRemainingCharacters
              && <span className="yext-input__character-count">
                {yext.msg('Characters remaining')}: {maxLength - value.length}
              </span>
        }
      </div>
    </div>
  );
});
