// @ts-nocheck
goog.declareModuleId('yext.ui.components.select.standard.FilteredSelectList');

import yext from 'goog:yext';

import {optionStates} from '/ui/components/select/model/optionstates';
import {MultiSelectOption} from '/ui/components/select/standard/multiselectoption';
import {SelectList} from '/ui/components/select/standard/selectlist';
import {SelectOption} from '/ui/components/select/standard/selectoption';
import {Option} from '/ui/components/select/types/option';

/**
 * @param {{multiselect: (boolean|undefined),
 *          onOptionChange: (Function|undefined),
 *          onSelectChange: (Function|undefined),
 *          options: (Array<Option>|undefined),
 *          isVisible: boolean,
 *          height?: (number|undefined),
 * }} props
 */
export function FilteredSelectList(props) {
  const renderSection = section => {
    const OptionComponent = props.multiselect ? MultiSelectOption : SelectOption;
    return <React.Fragment key={section.label}>
      <li className="ui-select-filtered-section" {...{'role': 'presentation'}}>{section.label}</li>
      {section.options.map(o =>
        <OptionComponent
          option={o}
          key={o.value}
          onOptionChange={props.onOptionChange}
          onSelectChange={props.onSelectChange}
          checked={Option.getStatus(o) === optionStates.SELECTED}
          indeterminate={Option.getStatus(o) === optionStates.INDETERMINATE}
          ariaAttributes={{'role': 'option'}} />)}
    </React.Fragment>;
  };

  const rootOptions = props.options.filter(o => (!o.options || o.options.length === 0));
  const sections = props.options.filter(o => (o.options && o.options.length !== 0));
  const OptionComponent = props.multiselect ? MultiSelectOption : SelectOption;
  const noResultsMessage = yext.msg('No results found');
  return (!rootOptions.length && !sections.length)
    ? (
      <div className="ui-select-list" tabIndex="-1" style={{height: props.height}}>
        <span className="ui-select-empty-search-message">{noResultsMessage}</span>
      </div>
    ) : <SelectList tabIndex="-1" isCascade={false} hasCascade={false} ariaAttributes={{'role': 'listbox'}} isVisible={props.isVisible} height={props.height}>
      {rootOptions.map(o =>
        <OptionComponent
          option={o}
          key={o.value}
          onOptionChange={props.onOptionChange}
          onSelectChange={props.onSelectChange}
          checked={Option.getStatus(o) === optionStates.SELECTED}
          indeterminate={Option.getStatus(o) === optionStates.INDETERMINATE}
          ariaAttributes={{'role': 'option'}} />)}
      {sections.map(renderSection)}
    </SelectList>;
}
